export default [
  {
    path: '',
    name: 'home',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue')
  },
  {
    path: 'onboarding/enterprise',
    component: () => import(/* webpackChunkName: "onboarding" */ '@/views/onboarding/Enterprise.vue'),
    name: 'onboarding.enterprise'
  },
  {
    path: 'onboarding/bank-account',
    component: () => import(/* webpackChunkName: "onboarding" */ '@/views/onboarding/BankAccount.vue'),
    name: 'onboarding.bankAccount'
  },
  {
    path: 'onboarding/taxes',
    component: () => import(/* webpackChunkName: "onboarding" */ '@/views/onboarding/Taxes.vue'),
    name: 'onboarding.taxes'
  },
  {
    path: 'onboarding/public-profile',
    component: () => import(/* webpackChunkName: "onboarding" */ '@/views/onboarding/PublicProfile.vue'),
    name: 'onboarding.publicProfile'
  },
  {
    path: 'products',
    component: () => import(/* webpackChunkName: "products" */ '@/views/products/Index.vue'),
    name: 'products.index'
  },
  {
    path: 'products/import',
    component: () => import(/* webpackChunkName: "products" */ '@/views/products/import/CSVImport.vue'),
    name: 'products.import'
  },
  {
    path: 'products/create/:type(simple|variable)',
    component: () => import(/* webpackChunkName: "products" */ '@/views/products/CreateEdit.vue'),
    name: 'products.create'
  },
  {
    path: 'products/edit/:id',
    component: () => import(/* webpackChunkName: "products" */ '@/views/products/CreateEdit.vue'),
    name: 'products.edit'
  },
  {
    path: 'products/create-variant',
    component: () => import(/* webpackChunkName: "products" */ '@/views/products/create-edit/ProductVariant.vue'),
    name: 'products.createVariant'
  },
  {
    path: 'product-questions',
    component: () => import(/* webpackChunkName: "product-questions" */ '@/views/product-questions/Index.vue'),
    name: 'productQuestions.index'
  },
  {
    path: 'product-questions/:id',
    component: () => import(/* webpackChunkName: "product-questions" */ '@/views/product-questions/Details.vue'),
    name: 'productQuestions.details'
  },
  {
    path: 'orders',
    component: () => import(/* webpackChunkName: "orders" */ '@/views/orders/Index.vue'),
    name: 'orders.index'
  },
  {
    path: 'orders/:id',
    component: () => import(/* webpackChunkName: "orders" */ '@/views/orders/Details.vue'),
    name: 'orders.details'
  },
  {
    path: 'shipments',
    component: () => import(/* webpackChunkName: "shipments" */ '@/views/shipments/Index.vue'),
    name: 'shipments.index'
  },
  {
    path: 'shipments/create/:orderId',
    component: () => import(/* webpackChunkName: "shipments" */ '@/views/shipments/Create.vue'),
    name: 'shipments.create'
  },
  {
    path: 'shipments/:id',
    component: () => import(/* webpackChunkName: "shipments" */ '@/views/shipments/Details.vue'),
    name: 'shipments.details'
  },
  {
    path: 'returns',
    component: () => import(/* webpackChunkName: "returns" */ '@/views/returns/Index.vue'),
    name: 'returns.index'
  },
  {
    path: 'returns/:id',
    component: () => import(/* webpackChunkName: "returns" */ '@/views/returns/Details.vue'),
    name: 'returns.details'
  },
  {
    path: 'invoices',
    component: () => import(/* webpackChunkName: "invoices" */ '@/views/invoices/Index.vue'),
    name: 'invoices.index'
  },
  {
    path: 'invoices/:id',
    component: () => import(/* webpackChunkName: "invoices" */ '@/views/invoices/Details.vue'),
    name: 'invoices.details'
  },
  {
    path: 'credit-notes',
    component: () => import(/* webpackChunkName: "credit-notes" */ '@/views/credit-notes/Index.vue'),
    name: 'creditNotes.index'
  },
  {
    path: 'credit-notes/:id',
    component: () => import(/* webpackChunkName: "credit-notes" */ '@/views/credit-notes/Details.vue'),
    name: 'creditNotes.details'
  },
  {
    path: 'transfers',
    component: () => import(/* webpackChunkName: "transfers" */ '@/views/transfers/Index.vue'),
    name: 'transfers.index'
  },
  {
    path: 'transfers/:id',
    component: () => import(/* webpackChunkName: "transfers" */ '@/views/transfers/Details.vue'),
    name: 'transfers.details'
  },
  {
    path: 'settings/taxes',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Taxes.vue'),
    name: 'settings.taxes'
  },
  {
    path: 'settings/shipping',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Shipping.vue'),
    name: 'settings.shipping'
  },
  {
    path: 'settings/profile',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Profile.vue'),
    name: 'settings.profile'
  },
  {
    path: 'settings/public-profile',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/PublicProfile.vue'),
    name: 'settings.publicProfile'
  },
  {
    path: 'bank-accounts',
    component: () => import(/* webpackChunkName: "bank-accounts" */ '@/views/bank-accounts/Index.vue'),
    name: 'bankAccounts.index'
  },
  {
    path: 'bank-accounts/update',
    component: () => import(/* webpackChunkName: "bank-accounts" */ '@/views/bank-accounts/Update.vue'),
    name: 'bankAccounts.update'
  },
  {
    path: 'account',
    component: () => import(/* webpackChunkName: "my-account" */ '@/views/account/Index.vue'),
    name: 'account'
  },
  {
    path: 'support/requests',
    component: () => import(/* webpackChunkName: "support" */ '@/views/support/requests/Index.vue'),
    name: 'supportRequests.index'
  },
  {
    path: 'support/requests/:id',
    component: () => import(/* webpackChunkName: "support" */ '@/views/support/requests/Details.vue'),
    name: 'supportRequests.details'
  },
  {
    path: 'customer-messages',
    component: () => import(/* webpackChunkName: "customer-messages" */ '@/views/customer-messages/Index.vue'),
    name: 'customerMessages.index'
  },
  {
    path: 'customer-messages/:orderId',
    component: () => import(/* webpackChunkName: "customer-messages" */ '@/views/customer-messages/Thread.vue'),
    name: 'customerMessages.thread'
  },
  {
    path: 'documentations/product-categories',
    component: () => import(/* webpackChunkName: "documentations" */ '@/views/documentations/ProductCategories.vue'),
    name: 'documentations.productCategories'
  },
  {
    path: 'connected-store',
    component: () => import(/* webpackChunkName: "connected-store" */ '@/views/connected-store/Index.vue'),
    name: 'connectedStore'
  },
  {
    path: 'connected-store/setup/shopify',
    component: () => import(/* webpackChunkName: "connected-store" */ '@/views/connected-store/ShopifySetup.vue'),
    name: 'connectedStore.setup.shopify'
  },
  {
    path: 'connected-store/setup/woocommerce',
    component: () => import(/* webpackChunkName: "connected-store" */ '@/views/connected-store/WooCommerceSetup.vue'),
    name: 'connectedStore.setup.woocommerce'
  },
  {
    path: 'faqs',
    component: () => import(/* webpackChunkName: "faqs" */ '@/views/faqs/Index.vue'),
    name: 'faqs.index'
  }
]
