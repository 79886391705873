import Vue from 'vue'
import { cloneDeep } from 'lodash-es'
import { createPaginatedStore } from '@/utils/store'
import http from '@/utils/http'
import { getQueryParams, IQueryParams } from '@/utils/query-params'
import { mutations } from '@/utils/store-pagination'

const store = createPaginatedStore({
  fetchById: async ({ id, storeId }) => {
    const { data: productQuestion } = await http.get(`/v1/stores/${storeId}/product-questions/${id}`)
    return productQuestion
  }
}, {
  state: {
    statusFilter: '',
    sortBy: 'createdAt',
    sortDirection: 'DESC'
  },

  mutations,

  actions: {
    fetch: async ({ commit, state }: any, data: { page: number | null; storeId: string }) => {
      commit('START_FETCHING')

      const params: IQueryParams = getQueryParams(data, state)

      commit('SET_PAGE', params.page)

      const { data: productQuestions } = await http.get(`/v1/stores/${data.storeId}/product-questions`, {
        params
      })

      commit('FETCH_SUCCESS', productQuestions)

      return productQuestions
    },

    discard: async ({ commit }: any, { storeId, id }: { storeId: string; id: string }) => {
      try {
        const { data: productQuestion } = await http.patch(`/v1/stores/${storeId}/product-questions/${id}`, {
          status: 'discarded'
        })

        commit('ITEM_UPDATED', productQuestion)
      } catch {
        Vue.toasted.error("Une erreur s'est produite. Veuillez réessayer.")
      }
    },

    reply: async ({ commit }: any, { storeId, id, answer }: { storeId: string; id: string; answer: string }) => {
      try {
        const { data: productQuestion } = await http.patch(`/v1/stores/${storeId}/product-questions/${id}`, {
          status: 'published',
          answer
        })

        commit('ITEM_UPDATED', productQuestion)
      } catch {
        Vue.toasted.error("Une erreur s'est produite. Veuillez réessayer.")
      }
    }
  }
})

export default {
  ...store,
  state: () => {
    return cloneDeep(store.state)
  }
}
