import { cloneDeep } from 'lodash-es'
import { createPaginatedStore } from '@/utils/store'
import http from '@/utils/http'
import { getQueryParams, IQueryParams } from '@/utils/query-params'
import { mutations } from '@/utils/store-pagination'
import customerMessagesHistory from '@/store/modules/customerMessagesHistory'

const store = createPaginatedStore({}, {
  state: {},

  mutations,

  actions: {
    fetch: async ({ commit, state }: any, data: { page: number | null; storeId: string }) => {
      commit('START_FETCHING')

      const params: IQueryParams = getQueryParams(data, state)

      commit('SET_PAGE', params.page)

      const { data: customerMessages } = await http.get(`/v1/stores/${data.storeId}/customer-messages`, {
        params
      })

      commit('FETCH_SUCCESS', customerMessages)

      return customerMessages
    }
  },

  modules: {
    history: customerMessagesHistory
  }
})

export default {
  ...store,
  state: () => {
    return cloneDeep(store.state)
  }
}
