import { filter, assign, find } from 'lodash-es'
import { spliceCollection, triggerMethod } from '@/utils/store/helpers'
import {
  DocumentCollection,
  GenericObject,
  INestedCollectionStoreMethods,
  ICustomStore,
  INestedCollectionState,
  WithId
} from './types'

const defaultCustomStore = {
  modules: {},
  state: {},
  getters: {},
  mutations: {},
  actions: {}
}

const getOrCreateStatusByParent = (state: INestedCollectionState, parentId: string) => {
  return state.statusByParent[parentId] || {
    isFetching: false,
    isFetched: false,
    isRefreshing: false,
    fetchedAt: null
  }
}

const getOrCreateListByParent = (state: INestedCollectionState, parentId: string) => {
  return state.listsByParent[parentId] || []
}

export const createNestedCollectionStore = (methods: INestedCollectionStoreMethods, customStoreConfig: ICustomStore = {}) => {
  const customStore = {
    ...defaultCustomStore,
    ...customStoreConfig
  }

  const state: INestedCollectionState = {
    listsByParent: {},
    statusByParent: {},
    ...customStore.state
  }

  const getters = {
    isListFetched: (state: INestedCollectionState) => (parentId: string): boolean => {
      if (state.statusByParent[parentId]) {
        return state.statusByParent[parentId].isFetched
      }
      return false
    },

    isListFetching: (state: INestedCollectionState) => (parentId: string): boolean => {
      if (state.statusByParent[parentId]) {
        return state.statusByParent[parentId].isFetching
      }
      return false
    },

    isListRefreshing: (state: INestedCollectionState) => (parentId: string): boolean => {
      if (state.statusByParent[parentId]) {
        return state.statusByParent[parentId].isRefreshing
      }
      return false
    },

    getList: (state: INestedCollectionState) => (parentId: string): DocumentCollection => {
      return state.listsByParent[parentId] || []
    },

    getItemById: (state: INestedCollectionState) => (parentId: string, id: string): WithId<GenericObject> | null => {
      const list: DocumentCollection = getOrCreateListByParent(state, parentId)
      return find(list, item => item.id === id) || null
    },

    ...customStore.getters
  }

  const mutations = {
    RESET_STATE: (state: INestedCollectionState) => {
      Object.assign(state, {
        listsByParent: {},
        statusByParent: {},
        ...customStore.state
      })
    },

    START_FETCHING: (state: INestedCollectionState, parentId: string) => {
      const status = getOrCreateStatusByParent(state, parentId)

      state.statusByParent = {
        ...state.statusByParent,
        [parentId]: {
          ...status,
          isFetching: true,
          isFetched: false
        }
      }
    },

    FETCH_SUCCESS: (state: INestedCollectionState, { parentId, collection }: { parentId: string; collection: DocumentCollection }) => {
      state.listsByParent = {
        ...state.listsByParent,
        [parentId]: collection
      }

      const status = getOrCreateStatusByParent(state, parentId)

      state.statusByParent = {
        ...state.statusByParent,
        [parentId]: {
          ...status,
          isFetching: false,
          isFetched: true
        }
      }
    },

    FETCH_FAILURE: (state: INestedCollectionState, parentId: string) => {
      const status = getOrCreateStatusByParent(state, parentId)

      state.statusByParent = {
        ...state.statusByParent,
        [parentId]: {
          ...status,
          isFetching: false
        }
      }
    },

    START_REFRESHING: (state: INestedCollectionState, parentId: string) => {
      const status = getOrCreateStatusByParent(state, parentId)

      state.statusByParent = {
        ...state.statusByParent,
        [parentId]: {
          ...status,
          isRefreshing: true
        }
      }
    },

    REFRESH_SUCCESS: (state: INestedCollectionState, { parentId, collection }: { parentId: string; collection: DocumentCollection }) => {
      state.listsByParent = {
        ...state.listsByParent,
        [parentId]: collection
      }

      const status = getOrCreateStatusByParent(state, parentId)

      state.statusByParent = {
        ...state.statusByParent,
        [parentId]: {
          ...status,
          isRefreshing: false
        }
      }
    },

    REFRESH_FAILURE: (state: INestedCollectionState, parentId: string) => {
      const status = getOrCreateStatusByParent(state, parentId)

      state.statusByParent = {
        ...state.statusByParent,
        [parentId]: {
          ...status,
          isRefreshing: false
        }
      }
    },

    ITEM_CREATED: (state: INestedCollectionState, { parentId, item }: { parentId: string; item: WithId<GenericObject> }) => {
      const list = getOrCreateListByParent(state, parentId)

      state.listsByParent = {
        ...state.listsByParent,
        [parentId]: [
          ...list,
          item
        ]
      }
    },

    ITEM_UPDATED: (state: INestedCollectionState, { parentId, item }: { parentId: string; item: WithId<GenericObject> }) => {
      const list = [...getOrCreateListByParent(state, parentId)]

      spliceCollection(list, item.id, item)

      state.listsByParent = {
        ...state.listsByParent,
        [parentId]: [
          ...list
        ]
      }
    },

    ITEM_DELETED: (state: INestedCollectionState, { parentId, id }: { parentId: string; id: string }) => {
      const list = getOrCreateListByParent(state, parentId)

      state.listsByParent = {
        ...state.listsByParent,
        [parentId]: filter(list, item => item.id !== id)
      }
    },

    ...customStore.mutations
  }

  const actions = {}

  if (methods && methods.fetch) {
    assign(actions, {
      fetch: async ({ commit }: any, payload: { parentId: string } & { [key: string]: any }) => {
        commit('START_FETCHING', payload.parentId)

        try {
          const items = await triggerMethod(methods, 'fetch', payload)
          const collection = Array.isArray(items) ? items : []

          commit('FETCH_SUCCESS', {
            parentId: payload.parentId,
            collection
          })
          return collection
        } catch (e) {
          commit('FETCH_FAILURE', payload.parentId)
          throw e
        }
      },

      refresh: async ({ commit }: any, payload: { parentId: string } & { [key: string]: any }) => {
        commit('START_REFRESHING', payload.parentId)

        try {
          const items = await triggerMethod(methods, 'fetch', payload)
          const collection = Array.isArray(items) ? items : []

          commit('REFRESH_SUCCESS', {
            parentId: payload.parentId,
            collection
          })
          return collection
        } catch (e) {
          commit('REFRESH_FAILURE', payload.parentId)
          throw e
        }
      },

      fetchOrRefresh: ({ state, dispatch }: any, payload: { parentId: string } & { [key: string]: any }) => {
        if (state.statusByParent[payload.parentId] && state.statusByParent[payload.parentId].isFetched) {
          return dispatch('refresh', payload)
        } else {
          return dispatch('fetch', payload)
        }
      }
    })
  }

  if (methods && methods.create) {
    assign(actions, {
      create: async ({ commit }: any, { parentId, data }: { parentId: string; data: Record<string, any> }) => {
        const object = await triggerMethod(methods, 'create', { parentId, data })

        commit('ITEM_CREATED', {
          parentId,
          item: object
        })

        return object
      }
    })
  }

  if (methods && methods.update) {
    assign(actions, {
      update: async ({ commit }: any, { parentId, data }: { parentId: string; data: Record<string, any> }) => {
        const object = await triggerMethod(methods, 'update', { parentId, data })

        commit('ITEM_UPDATED', {
          parentId,
          item: object
        })

        return object
      }
    })
  }

  if (methods && methods.delete) {
    assign(actions, {
      delete: async ({ commit }: any, { parentId, id }: { parentId: string; id: string }) => {
        await triggerMethod(methods, 'delete', { parentId, id })
        commit('ITEM_DELETED', { parentId, id })
      }
    })
  }

  assign(actions, {
    load: ({ commit }: any, { parentId, items }: { parentId: string; items: DocumentCollection }) => {
      commit('FETCH_SUCCESS', {
        parentId,
        collection: items
      })
    }
  })

  assign(actions, customStore.actions)

  return {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {
      ...customStore.modules
    }
  }
}
