import http from '@/utils/http'

interface CommissionRateState {
  data: CommissionRate;
}

interface CommissionRate {
  id: string;
  storeId: string;
  rate: number;
  startDate: string | null;
  endDate: string | null;
}

export default {
  namespaced: true,

  state: {
    data: null
  },

  mutations: {
    RESET_STATE: (state: CommissionRateState) => {
      state.data = {} as CommissionRate
    },
    COMMISSION_RATE_FETCHED: (state: CommissionRateState, commissionRate: CommissionRate) => {
      state.data = commissionRate
    }
  },

  actions: {
    async fetch ({ commit }: any, storeId: string): Promise<CommissionRate | null> {
      const { data: commissionRate } = await http.get(`/v1/stores/${storeId}/commission-rate`)

      commit('COMMISSION_RATE_FETCHED', commissionRate)
      return commissionRate
    }
  }
}
