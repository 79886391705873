import http from '@/utils/http'
import { IFaqItem } from '@/store/types/faqItems'

export default {
  namespaced: true,

  state: {
    data: [],
    loading: false
  },

  mutations: {
    SET_SELLERS_FAQS_LOADING (state: any, loading: boolean) {
      state.loading = loading
    },
    SET_SELLERS_FAQS (state: any, faqs: IFaqItem[]) {
      state.data = faqs
    }
  },

  actions: {
    async fetchSellersFaqs ({ commit }: any): Promise<void> {
      commit('SET_SELLERS_FAQS_LOADING', true)

      const { data } = await http.get<IFaqItem[]>('/v1/faq/faq-vendeurs')

      commit('SET_SELLERS_FAQS', data)
      commit('SET_SELLERS_FAQS_LOADING', false)
    }
  }
}
