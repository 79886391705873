<template>
  <dropdown align="right">
    <template v-slot:button="{ open }">
      <div class="mr-md-4 mr-3 d-flex align-items-center">
        <fa-icon :icon="['far', 'question-circle']" class="support-icon" @click="open" />
      </div>
    </template>
    <template v-slot:panel>
      <div class="card">
        <div class="card-body p-1">
          <nav class="nav flex-column">
            <a class="nav-link" href="" @click.prevent="handleNavigateToSupportRequests">Support</a>
            <router-link class="nav-link" to="/faqs">Faq</router-link>
          </nav>
        </div>
      </div>
    </template>
  </dropdown>
</template>

<script>
import Dropdown from '@/components/common/Dropdown'

export default {
  components: {
    Dropdown
  },
  methods: {
    handleNavigateToSupportRequests () {
      return this.$router.push({ name: 'supportRequests.index' })
    }
  }
}
</script>

<style scoped lang="scss">
.support-icon {
  font-size: 28px;
  color: #63B3ED;
  opacity: 0.9;
  cursor: pointer;
}
.nav-link {
  color: #212529;
}
</style>
