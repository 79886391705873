<template>
  <div class="table-responsive">
    <table class="collection-table table" :class="{ 'border-top-none': noTopBorder, 'table-hover': hover }">
      <thead v-if="showHeader">
      <tr>
        <th v-for="col in cols" :class="[col.headerClass, col.isSortable ? 'cursor' : 'no-cursor']" :key="`header-col-${col.property}`" @click.prevent="sortBy(col)">
          <slot v-if="col.headerCheckbox" name='selectRow'></slot>
          <template v-if="(col.isSortable && !onlyDisplaySortCol) || (onlyDisplaySortCol && sort === col.property)">
            <fa-icon class="fa" :class="{ 'sort-disable': sort !== col.property }" :icon="['fas', sort === col.property && sortDirection === 'ASC' ? 'chevron-down' : 'chevron-up']"></fa-icon>
          </template>
          {{ col.header }}
        </th>
        <th v-if="showActions"></th>
      </tr>
      </thead>
      <tbody class="table-body">
        <template>
          <!-- eslint-disable-next-line -->
          <tr v-if="isFetching" class="bg-white">
            <td class="text-center loading" colspan="100%">
              <b-spinner></b-spinner>
            </td>
          </tr>
          <!-- eslint-disable-next-line -->
          <template v-for="(item, index) in listItems">
            <row :key="item[keyName]"
              :cols="cols"
              :object="item"
              :on-edit="onEdit"
              :on-select="onSelect"
              :on-delete="onDelete"
              :on-trigger="onTrigger"
              :action-dropdown="actionDropdown"
              @action="handleAction"
              :custom-actions="getCustomActions(item)"
              :default-actions="defaultActions"
              :show-actions="showActions"
              :link="getLink(item)"
              :class="[{'row-danger': hasError(item)}, {'row-warning': !hasError(item) && hasWarning(item)}, getRowClass(item)]"
              :has-error="hasError(item)"
              v-b-toggle="handleToggle(item, index)"
            >
            </row>
            <!-- eslint-disable-next-line vue/valid-v-for -->
            <collapsible-alert-row
              v-if="hasError(item)"
              :key="`${item[keyName]}-${index}-error`"
              :colspan="cols.length"
              :collapsed-id="`collapse-error-${index}`"
              label="Contient des erreurs"
              variant="danger"
              :messages="getErrorMessage(errors[item[errorIdentifier]])"
            />
            <!-- eslint-disable-next-line vue/valid-v-for -->
            <collapsible-alert-row
              v-if="hasWarning(item)"
              :key="`${item[keyName]}-${index}-warning`"
              :colspan="cols.length"
              :collapsed-id="`collapse-warning-${index}`"
              label="Avertissements"
              variant="warning"
              :messages="getWarningMessage(warnings[item[errorIdentifier]])"
            />
          </template>
        </template>
        <tr v-if="!listItems.length && !isFetching" class="bg-white">
          <td :colspan="cols.length">
           <empty-state :src="emptyImgSrc" :message="emptyMessage" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { VBToggle } from 'bootstrap-vue'
import Row from './CollectionTableRow'
import CollapsibleAlertRow from '@/components/data/tables/CollapsibleAlertRow'
import EmptyState from '@/components/common/EmptyState'

export default {
  components: {
    EmptyState,
    CollapsibleAlertRow,
    Row
  },
  directives: {
    'b-toggle': VBToggle
  },
  props: {
    listItems: {
      type: Array,
      required: true,
      default () {
        return []
      }
    },
    cols: {
      type: Array,
      required: true,
      default () {
        return []
      }
    },
    onSelect: {
      type: Function,
      required: false,
      default: () => null
    },
    onEdit: {
      type: Function,
      required: false,
      default: () => null
    },
    onDelete: {
      type: Function,
      required: false,
      default: () => null
    },
    onTrigger: {
      type: Function,
      required: false,
      default: () => null
    },
    showHeader: {
      type: Boolean,
      required: false,
      default: true
    },
    showActions: {
      type: Boolean,
      required: false,
      default: true
    },
    actionDropdown: {
      type: Boolean,
      required: false,
      default: true
    },
    keyName: {
      type: String,
      required: false,
      default: 'id'
    },
    customActions: {
      type: [Array, Function],
      required: false,
      default () {
        return []
      }
    },
    defaultActions: {
      type: Boolean,
      required: false,
      default: true
    },
    noTopBorder: {
      type: Boolean,
      default: false
    },
    link: {
      type: Function
    },
    hover: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object,
      default: () => null
    },
    errorIdentifier: {
      type: String,
      default: 'id'
    },
    getErrorMessage: {
      type: Function,
      default: null
    },
    warnings: {
      type: Object,
      default: () => null
    },
    getWarningMessage: {
      type: Function,
      default: null
    },
    emptyMessage: {
      type: String,
      required: false,
      default: 'Aucune donnée de disponible'
    },
    emptyImgSrc: {
      type: String,
      required: false,
      default: require('@/assets/images/icons/empty.svg')
    },
    sort: {
      type: String,
      default: null
    },
    sortDirection: {
      type: String,
      default: 'DESC'
    },
    onlyDisplaySortCol: {
      type: Boolean,
      default: false
    },
    isFetching: {
      type: Boolean,
      default: false
    },
    rowClass: {
      type: Function
    }
  },
  methods: {
    handleAction (eventName, object) {
      this.$emit(eventName, object)
    },
    getCustomActions (object) {
      return (typeof this.customActions === 'function')
        ? this.customActions(object) : this.customActions
    },
    getLink (object) {
      return (typeof this.link === 'function')
        ? this.link(object) : null
    },
    getRowClass (object) {
      return (typeof this.rowClass === 'function')
        ? this.rowClass(object) : null
    },
    hasError (object) {
      // returns the errors object where every key === errorIdentifier (ex: { '123': { name: ['error1', 'error2'] } }
      return !!this.errors && !!this.errors[object[this.errorIdentifier]]
    },
    hasWarning (object) {
      // returns the warnings object where every key === errorIdentifier (ex: { '123': { name: ['warning1', 'warning2'] } }
      return !!this.warnings && !!this.warnings[object[this.errorIdentifier]]
    },
    handleToggle (item, index) {
      if (this.hasError(item)) {
        return `collapse-error-${index}`
      }

      if (this.hasWarning(item)) {
        return `collapse-warning-${index}`
      }

      return null
    },
    sortBy (col) {
      if (!col.isSortable) {
        return
      }

      const direction = this.sortDirection === 'DESC' ? 'ASC' : 'DESC'

      this.$emit('sortBy', { property: col.property, direction })
    }
  }
}
</script>

<style lang="scss">
.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}
.collection-table {
  margin-bottom: 0;

  .cursor {
    cursor: pointer;
  }

  .no-cursor {
    cursor: no-drop;
  }

  .sort-disable {
    color: grey;
  }

  &.border-top-none {
    thead {
      th {
        border-top: 0;
      }
    }
  }

  th {
    font-size: 14px;
    white-space: nowrap;
  }

  .table-body {
    font-size: 14px;

    tr {
      cursor: unset;
      &,
      &:active,
      &:focus,
      &:hover {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        outline: 0;
      }
    }

    td {
      vertical-align: middle;
    }
  }

  .col-action {
    width: 16px;
  }

  .row-danger {
    cursor: pointer !important;
    border-left: 5px solid #dc3545;
  }

  .row-warning {
    cursor: pointer !important;
    border-left: 5px solid #ffc107;
  }
}
</style>
